import React from "react";

const AboutUs = ({classicHeader, darkTheme}) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            O mně
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Poznejte mě více
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto"/>
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              <span className="text-primary">Patricie Linek Honslová,</span> marketingový specialista
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
              Pomohu vám růst. Budovat vaši značku za přijatelnou cenu. Navrhnu pro vás efektivní marketingovou
              strategii pro vaše stávající nebo nové produkty. Nabídnu vám pomoc při identifikaci správné cílové
              skupiny, přípravě propagačních plánů a výběru nejvhodnějších kanálů propagace. Vyhledávám nové
              příležitosti pro váš business.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              Specializuji se na brand marketing a obsahový marketing, kde se zaměřuji na komunikační styl, povědomí o
              vaší značce, vizuální prezentaci a vytváření vztahů a povědomí. Pomohu vám také s prezentací a komunikací
              na sociálních sítích.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Jméno:</span>Patricie Linek Honslová
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:phonslova@gmail.com">phonslova@gmail.com</a>
                </li>
                <li>
                  <span className="fw-600 me-2">Telefon:</span>
                  <a href="tel:+420603874075">+420&nbsp;603&nbsp;874&nbsp;075</a>
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">Místo:</span>Praha
                </li>
              </ul>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>25</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Roky zkušeností
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>35</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Produktů
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>100</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Marketingových kampaní
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>15</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Uvedení na trh
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
