import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";

const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [imagesLoaded, setimagesLoaded] = useState(0);

  const projectsData = [
    {
      title: "Marianne",
      thumbImage: "images/projects/dny-marianne.svg",
    },
    {
      title: "Hotel Pecr Deep a hotel Pecr Well****",
      thumbImage: "images/projects/pecr.svg",
    },
    {
      title: "Kondice",
      thumbImage: "images/projects/kondice.svg",
    },
    {
      title: "Elle",
      thumbImage: "images/projects/elle.svg",
    },
    {
      title: "Jóga Event",
      thumbImage: "images/projects/joga-event.svg",
    },
    {
      title: "Apetit",
      thumbImage: "images/projects/apetit.svg",
    },
    {
      title: "Beachklub",
      thumbImage: "images/projects/beachklub.svg",
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    isotope.current.arrange({ filter: `*` })
  }, [imagesLoaded]);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              Moje práce
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className="col-sm-6 col-lg-4 filter-item"
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block"
                          src={project.thumbImage}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
