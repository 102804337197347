import React from "react";

const Resume = ({classicHeader, darkTheme}) => {
  const experienceDetails = [
    {
      yearRange: "2019 – stále",
      title: "Hotel Pecr Deep a  hotel Pecr Well****",
      place: "Co-owner Hotel Pecr Apartments, manager marketingu",
      desc: "Hotelový marketing, brand marketing, online marketing.  Zodpovědnost a realizace veškerých propagačních kampaní hotelu včetně direct marketingu. Prezentace pro firemní zákazníky hotelu. ",
    },
    {
      yearRange: "2019 – 2022",
      title: "Beachvolejbalový areál Beachklub Pankrác",
      place: "Co-owner Sportzone a.s., manager marketingu ",
      desc: "Sportovní marketing, online marketing, brand marketing. Správa sociálních sítí, online kampaně, návrh a správa webu, jednání se sponzory.",
    },
    {
      yearRange: "2014 – 2020",
      title: "Vydavatelství Astrosat resp. Vltava Labe Media",
      place: "Publisher",
      desc: "Řízení týmu 30 lidí (redakce, distribuce, inzertní marketing, marketingové oddělení). Zodpovědnost za marketingový rozpočet, ekonomika časopisů, rozvoje značek, marketingová strategie, podpora prodeje.",
    },
    {
      yearRange: "2011 – 2014",
      title: "Vydavatelství Easy Publishing",
      place: "Co-owner, jednatelka",
      desc: "Vydávání časopisu Kondice a  speciálů o zdraví a jídle. Zajištění financování firmy,  inzertní strategie, jednání s agenturami, tiskárnami, distribučními firmami. Řízení prodejního týmu, podpora prodeje, marketing.",
    },
    {
      yearRange: "1999 - 2011",
      title: "Hachette Filipacchi, vydavatelství časopisů",
      place: "Ředitelka marketingu a distribuce",
      desc: "Řízení oddělení marketingu, distribuce a předplatného. Zodpovědnost za propagaci a podporu prodeje časopisů Elle, Marianne, Apetit, Marianne Bydlení. Organizace eventů Dny Marianne, Elle Style Awards a další.",
    },
    {
      yearRange: "1999",
      title: "BMW AuTec, Grade",
      place: "Manager marketingu",
      desc: "Manager marketingu u importéra BMW AuTec a další marketingové zkušenosti jako account manager v reklamní agentuře Grade pro značky Atomic, Nesquik, BMW a další. ",
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Životopis
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Mé zkušenosti
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto"/>
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Experience */}
          {experienceDetails.length > 0 &&
            experienceDetails.map((value, index) => (
              <div className="col-md-6 mb-4" key={index}>
                <div
                  className={
                    "bg-white rounded h-100 p-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Resume;
